<template>
    <div class="p-3">
        <b-dropdown variant="dark" class="mr-3" right>
            <template #button-content>
                Constraint
                <span class="text-info" v-if="constraint">
                    ({{ constraint }})
                </span>
            </template>
            <b-dropdown-item
                @click="filterConstraint(constraint)"
                :key="constraint"
                v-for="constraint in constraints"
            >
                {{ constraint }}
            </b-dropdown-item>
        </b-dropdown>
        <b-dropdown variant="dark" class="mr-3" right>
            <template #button-content>
                Status
                <span class="text-info" v-if="status"> ({{ status }}) </span>
            </template>
            <b-dropdown-item
                @click="filterStatus(status)"
                :key="status"
                v-for="status in statuses"
            >
                {{ status }}
            </b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item variant="danger" @click="filterStatus(null)">
                <font-awesome-icon icon="fa-light fa-times" />
                Reset
            </b-dropdown-item>
        </b-dropdown>
        <b-dropdown variant="dark" right>
            <template #button-content>
                Country
                <span class="text-info" v-if="currentCountry">
                    ({{ currentCountry.name }})
                </span>
            </template>
            <b-dropdown-item
                @click="filterCountry(country)"
                :key="country.id"
                v-for="country in countries"
            >
                <img :src="country.flagUrl" />

                {{ country.name }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            country: null,
            status: null,
            constraint: null,
        };
    },
    mounted() {
        this.$store.dispatch("userFilters/loadOnce");

        this.constraint = this.$route.query?.constraint;
        this.status = this.$route.query?.status;
    },
    methods: {
        filterConstraint(constraint) {
            this.constraint = constraint;

            this.pushFilter({
                constraint,
            });
        },
        filterStatus(status) {
            this.status = status;

            this.pushFilter({
                status,
            });
        },
        filterCountry(country) {
            this.country = country;

            this.pushFilter({
                countryId: country.id,
            });
        },
        pushFilter(filter) {
            const query = {
                ...this.$route.query,
                ...filter,
            };

            console.log(query);

            this.$emit("filtersUpdated", query);
        },
    },
    computed: {
        currentCountry() {
            if (this.$route.query?.countryId && this.countries) {
                return this.countries?.find(
                    (country) =>
                        country.id === parseInt(this.$route.query?.countryId)
                );
            } else {
                return null;
            }
        },
        ...mapState("userFilters", [
            "filtersEnabled",
            "loaded",
            "countries",
            "metas",
            "landings",
            "sources",
            "tiers",
            "statuses",
            "constraints",
        ]),
    },
};
</script>