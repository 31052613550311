<template>
    <generic-page>
        <div :key="user?.model?.id" v-if="user">
            <user-sticky-alert />
            <user-vetted-ban />
            <user-screen v-if="$route.query?.screen" />
            <user-review v-if="$route.query?.review" />
            <user-authenticity v-if="$route.query?.authenticity" />
            <user-vetting v-if="$route.query?.vetting" />
            <user-immediate v-if="$route.query?.immediate" />
            <user-buyer v-if="$route.query?.buyer" />
            <user-buyer-risk v-if="$route.query?.buyerRisk" />
            <user-document v-if="$route.query?.document" />
            <user-id v-if="$route.query?.id" />

            <div :class="`border-status border-${user.statusVariant}`">
                <b-row no-gutters>
                    <b-col
                        cols="auto"
                        :style="$screen.lg ? 'width: 300px' : ''"
                        class="border-right"
                    >
                        <user-primary />
                        <user-fields />
                    </b-col>

                    <b-col>
                        <div>
                            <user-actions />
                        </div>
                        <b-row no-gutters>
                            <b-col>
                                <b-container>
                                    <user-navigation />
                                    <user-tabs />
                                </b-container>
                            </b-col>
                            <b-col
                                cols="auto"
                                style="width: 350px"
                                class="border-left"
                            >
                                <user-notes :enableDelete="true" />
                                <user-logs />
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
        </div>
        <div v-else-if="notFound">
            <div class="text-center my-5">
                <h1 class="display-3">Not Found</h1>
                <h5>
                    User may have been deleted, or the link may have been
                    invalid
                </h5>
                <b-btn class="mt-3" variant="primary" size="lg" to="/">
                    Dashboard
                </b-btn>
            </div>
        </div>
        <div v-else>
            <generic-loading />
        </div>
    </generic-page>
</template>

<script>
import { mapState } from "vuex";

import UserPrimary from "@/components/user/UserPrimary";
import UserLogs from "@/components/user/UserLogs";
import UserActions from "@/components/user/UserActions";
import UserFields from "@/components/user/UserFields";
import UserNavigation from "@/components/user/UserNavigation";
import UserTabs from "@/components/user/UserTabs";
import UserVetting from "@/components/user/UserVetting";
import UserImmediate from "@/components/user/UserImmediate";
import UserAuthenticity from "@/components/user/UserAuthenticity";
import UserReview from "@/components/user/UserReview";
import UserScreen from "@/components/user/UserScreen";
import UserStickyAlert from "@/components/user/UserStickyAlert";
import UserNotes from "@/components/user/UserNotes";
import UserVettedBan from "@/components/user/UserVettedBan";
import UserBuyer from "@/components/user/UserBuyer";
import UserBuyerRisk from "@/components/user/UserBuyerRisk";
import UserDocument from "@/components/user/UserDocument";
import UserId from "@/components/user/UserId";

export default {
    created() {
        this.refresh();
    },
    watch: {
        "$route.params.id": function (newId, oldId) {
            if (newId !== oldId) {
                this.$store.dispatch("userEdit/get", {
                    id: newId,
                    clear: true,
                });
            }
        },
    },
    computed: {
        currentTab() {
            return this.$route.params?.tab;
        },
        ...mapState("userEdit", ["user", "notFound"]),
        ...mapState("user", ["isRoot"]),
    },
    methods: {
        refresh() {
            this.$store.dispatch("userEdit/get", {
                id: this.$route.params.id,
                clear: true,
            });
        },
    },
    components: {
        UserPrimary,
        UserActions,
        UserTabs,
        UserFields,
        UserLogs,
        UserNavigation,
        UserReview,
        UserVetting,
        UserAuthenticity,
        UserImmediate,
        UserBuyer,
        UserScreen,
        UserStickyAlert,
        UserNotes,
        UserBuyerRisk,
        UserDocument,
        UserVettedBan,
        UserId,
    },
    metaInfo() {
        return {
            title: this.user
                ? `${this.user?.model?.name ?? "Unnamed"} (${
                      this.user.model.id
                  })`
                : null,
        };
    },
};
</script>