<template>
    <b-table-simple striped>
        <b-thead>
            <b-th v-if="withAvatar"> User </b-th>
            <b-th class="text-center"> Status </b-th>
            <b-th> Amount </b-th>
            <b-th class="text-center"> AVS </b-th>
            <b-th class="text-center"> CVV </b-th>
            <b-th> Postcode </b-th>
            <b-th> Distance </b-th>
            <b-th> Bank </b-th>
            <b-th> Last 4 </b-th>
            <b-th> Created </b-th>
            <b-th> </b-th>
        </b-thead>
        <b-tbody>
            <b-tr
                :key="authorizeTransaction.id"
                v-for="{
                    authorizeTransaction,
                    ninjaMap,
                    userCreditCard,
                    userCreditCardCountry,
                    bin,
                    binCountry,
                    country,
                } in authorizeTransactionIndex"
            >
                <td v-if="withAvatar">
                    <user-avatar v-if="ninjaMap" :user="ninjaMap" />
                </td>
                <td class="text-center">
                    <b-badge
                        v-if="authorizeTransaction.blocked"
                        variant="danger"
                    >
                        Blocked
                    </b-badge>
                    <b-badge
                        v-else-if="authorizeTransaction.voided"
                        variant="danger"
                    >
                        Voided
                    </b-badge>
                    <b-badge
                        v-else-if="authorizeTransaction.successful"
                        variant="dark"
                        class="text-success"
                    >
                        Success
                    </b-badge>
                    <b-badge
                        v-else
                        v-b-tooltip
                        :title="authorizeTransaction.errorMessage"
                        variant="dark"
                        class="text-warning"
                    >
                        Declined ({{
                            authorizeTransaction.errorCode || "Unknown"
                        }})
                    </b-badge>
                </td>
                <td>${{ authorizeTransaction.amount }}</td>
                <td
                    :class="`text-center text-${authorizeTransaction.avsVariant}`"
                >
                    {{ authorizeTransaction.avs }}
                </td>
                <td
                    :class="`text-center text-${authorizeTransaction.cvvVariant}`"
                >
                    {{ authorizeTransaction.cvv }}
                </td>
                <td>
                    <div v-if="userCreditCard">
                        <img
                            v-if="userCreditCardCountry"
                            :src="userCreditCardCountry.flagUrl"
                        />
                        {{ authorizeTransaction.postalCode }}
                    </div>
                    <div v-else>
                        <img v-if="country" :src="country.flagUrl" />
                        {{ authorizeTransaction.postalCode }}
                    </div>
                </td>
                <td>
                    {{ authorizeTransaction.distance }}
                </td>
                <td>
                    <b-link
                        v-if="bin?.modern"
                        :to="{
                            name: 'userIndex',
                            query: {
                                binId: bin.id,
                            },
                        }"
                    >
                        <img v-if="binCountry" :src="binCountry.flagUrl" />
                        {{ bin?.bankName }}
                        ({{ bin?.cardType }})
                    </b-link>
                </td>
                <td>
                    <div v-if="userCreditCard">
                        {{ userCreditCard?.lastFour }}
                    </div>
                    <div v-else>
                        {{ authorizeTransaction.lastFour }}
                    </div>
                </td>
                <td>
                    <live-timestamp :timestamp="authorizeTransaction.created" />
                </td>
                <td>
                    <b-btn
                        variant="light"
                        size="sm"
                        :href="`/authorizeTransaction/view/${authorizeTransaction.id}`"
                    >
                        View
                    </b-btn>
                </td>
            </b-tr>
        </b-tbody>
    </b-table-simple>
</template>

<script>
export default {
    props: ["authorizeTransactionIndex", "withAvatar"],
};
</script>