import api from "@/service/api";

const state = () => ({
    loading: false,
    tiles: null,
    wise: null,
    kraken: null,
    loadbalancer: null,
})

const mutations = {
    setTiles(state, value) {
        state.tiles = value;
    },
    setWise(state, value) {
        state.wise = value;
    },
    setKraken(state, value) {
        state.kraken = value;
    },
    setLoadbalancer(state, value) {
        state.loadbalancer = value;
    },
    setLoading(state, value) {
        state.loading = value;
    },
}

const actions = {
    refresh({ commit }) {
        commit('setLoading', true)

        api.post(`/dashboard/index`).then(response => {
            commit('setTiles', response.data.tiles)
            commit('setWise', response.data.wise)
            commit('setKraken', response.data.kraken)
            commit('setLoadbalancer', response.data.loadbalancer)
            commit('setLoading', false)
        })
    },
}



export default {
    namespaced: true,
    state,
    actions,
    mutations
}
