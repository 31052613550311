<template>
    <div>
        <h3>Bitcoin</h3>

        <div class="mt-3">
            <h3>
                <code>{{ withdraw.btcAddress }}</code>
            </h3>
        </div>
        <button
            class="btn btn-primary btn-lg w-100"
            @click="copyText(withdraw.btcAddress)"
        >
            Copy Wallet Address
        </button>

        <div class="mt-3">
            <h3>
                {{ description }}
            </h3>
        </div>
        <button
            class="btn btn-primary btn-lg w-100"
            @click="copyText(description)"
        >
            Copy Description
        </button>

        <div class="mt-3">
            <div v-if="withdraw.funded">Funded, transfer complete</div>
            <div v-else>
                <b-btn
                    :disabled="funding"
                    variant="success"
                    size="lg"
                    block
                    @click="fundBitcoin"
                >
                    Fund Transfer
                </b-btn>
            </div>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            funding: false,
        };
    },
    computed: {
        description() {
            return `${this.user.model.name} - ${this.user.model.id}`;
        },
        ...mapState("withdraw", ["withdraw", "user"]),
    },
    methods: {
        async fundBitcoin() {
            this.funding = true;

            await this.api.post(`/withdraw/${this.withdraw.id}/fundBitcoin`);
            this.$store.dispatch("withdraw/refresh");

            this.funding = false;
        },
        copyText(text) {
            navigator.clipboard.writeText(text);
        },
    },
};
</script>