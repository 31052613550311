<template>
    <b-nav-item-dropdown text="Root">
        <b-dropdown-item v-if="user.model.affiliate" @click="removeAffiliate">
            Remove Affiliate
        </b-dropdown-item>
        <b-dropdown-item v-else @click="convertAffiliate">
            Convert to Affiliate
        </b-dropdown-item>

        <b-dropdown-item v-if="user.model.ghost" @click="unghost">
            Un-ghost
        </b-dropdown-item>
        <b-dropdown-item v-else @click="makeGhost"> Ghost </b-dropdown-item>

        <b-dropdown-item v-if="!user.invitesRequired" @click="invitesRequired">
            Require Invite (buyer only)
        </b-dropdown-item>
        <b-dropdown-item v-else @click="removeInvitesRequired">
            Remove Invite Required
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="deleteAllSentMessages">
            Delete All Sent Messages
        </b-dropdown-item>
        <b-dropdown-item @click="restoreAllSentMessages">
            Restore All Sent Messages
        </b-dropdown-item>
        <b-dropdown-divider></b-dropdown-divider>
        <b-dropdown-item @click="authenticityCheck">
            Authenticity Check
        </b-dropdown-item>
    </b-nav-item-dropdown>
</template>

<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("userEdit", ["user", "tabs"]),
    },
    methods: {
        async authenticityCheck() {
            await this.api.post(
                `/user/${this.user.model.id}/authenticityCheck`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async deleteAllSentMessages() {
            await this.api.post(
                `/user/${this.user.model.id}/deleteAllSentMessages`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async restoreAllSentMessages() {
            await this.api.post(
                `/user/${this.user.model.id}/restoreAllSentMessages`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        // old untouched
        async removeInvitesRequired() {
            await this.api.post(
                `/user/${this.user.model.id}/removeInvitesRequired`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async invitesRequired() {
            await this.api.post(`/user/${this.user.model.id}/invitesRequired`);
            this.$store.dispatch("userEdit/refresh");
        },
        async unghost() {
            await this.api.post(`/user/${this.user.model.id}/unghost`);
            this.$store.dispatch("userEdit/refresh");
        },
        async makeGhost() {
            await this.api.post(`/user/${this.user.model.id}/ghost`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeHighRiskBuyer() {
            await this.api.post(
                `/user/${this.user.model.id}/removeHighRiskBuyer`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async highRiskBuyer() {
            await this.api.post(`/user/${this.user.model.id}/highRiskBuyer`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeDisregard() {
            await this.api.post(`/user/${this.user.model.id}/removeDisregard`);
            this.$store.dispatch("userEdit/refresh");
        },
        async disregard() {
            await this.api.post(`/user/${this.user.model.id}/disregard`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeHidden() {
            await this.api.post(`/user/${this.user.model.id}/removeHidden`);
            this.$store.dispatch("userEdit/refresh");
        },
        async hidden() {
            await this.api.post(`/user/${this.user.model.id}/hidden`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeCreator() {
            await this.api.post(`/user/${this.user.model.id}/removeCreator`);
            this.$store.dispatch("userEdit/refresh");
        },
        async makeCreator() {
            await this.api.post(`/user/${this.user.model.id}/creator`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeAffiliate() {
            await this.api.post(`/user/${this.user.model.id}/removeAffiliate`);
            this.$store.dispatch("userEdit/refresh");
        },
        async convertAffiliate() {
            await this.api.post(`/user/${this.user.model.id}/convertAffiliate`);
            this.$store.dispatch("userEdit/refresh");
        },
        async bypassOnboarding() {
            await this.api.post(`/user/${this.user.model.id}/bypassOnboarding`);
            this.$store.dispatch("userEdit/refresh");
        },
        async sugarbaby() {
            await this.api.post(`/user/${this.user.model.id}/sugarbaby`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeSugarbaby() {
            await this.api.post(`/user/${this.user.model.id}/removeSugarbaby`);
            this.$store.dispatch("userEdit/refresh");
        },
        async expat() {
            await this.api.post(`/user/${this.user.model.id}/expat`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeExpat() {
            await this.api.post(`/user/${this.user.model.id}/removeExpat`);
            this.$store.dispatch("userEdit/refresh");
        },
        async makeCensored() {
            await this.api.post(`/user/${this.user.model.id}/censored`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeCensored() {
            await this.api.post(`/user/${this.user.model.id}/censored`, {
                remove: true,
            });

            this.$store.dispatch("userEdit/refresh");
        },
        async lockdown() {
            await this.api.post(`/user/${this.user.model.id}/lockdown`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeLockdown() {
            await this.api.post(`/user/${this.user.model.id}/lockdown`, {
                remove: true,
            });

            this.$store.dispatch("userEdit/refresh");
        },
        async idRequired() {
            await this.api.post(`/user/${this.user.model.id}/idRequired`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeIdRequired() {
            await this.api.post(
                `/user/${this.user.model.id}/removeIdRequired`,
                {
                    remove: true,
                }
            );

            this.$store.dispatch("userEdit/refresh");
        },
        async criedWolf() {
            await this.api.post(`/user/${this.user.model.id}/criedWolf`);
            this.$store.dispatch("userEdit/refresh");
        },
        async remainVetted() {
            await this.api.post(`/user/${this.user.model.id}/remainVetted`);
            this.$store.dispatch("userEdit/refresh");
        },
        async allowTrackedKeywords() {
            await this.api.post(
                `/user/${this.user.model.id}/allowTrackedKeywords`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async allowNearbyHotspot() {
            await this.api.post(
                `/user/${this.user.model.id}/allowNearbyHotspot`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async allowInvalidLocale() {
            await this.api.post(
                `/user/${this.user.model.id}/allowInvalidLocale`
            );
            this.$store.dispatch("userEdit/refresh");
        },
        async whitelist() {
            await this.api.post(`/user/${this.user.model.id}/whitelist`);
            this.$store.dispatch("userEdit/refresh");
        },
        async carteblanche() {
            await this.api.post(`/user/${this.user.model.id}/carteblanche`);
            this.$store.dispatch("userEdit/refresh");
        },
    },
};
</script>