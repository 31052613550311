<template>
    <div>
        <user-alerts />

        <user-videos :vettingMode="true" />

        <user-hotspots />

        <b-table-simple class="userFields small" borderless>
            <b-tbody>
                <b-tr v-if="user.model.name">
                    <b-th style="width: 20%" class="bg-light"> Name </b-th>
                    <b-td class="py-0 align-middle">
                        <b-btn @click="wipeName" variant="light" size="sm">
                            <font-awesome-icon
                                icon="fa-light fa-times"
                                class="text-danger"
                            />
                        </b-btn>
                        {{ user.model.name }}
                    </b-td>
                </b-tr>
                <b-tr v-if="user.story">
                    <b-th style="width: 20%" class="bg-light"> About </b-th>
                    <b-td class="py-0 align-middle">
                        <b-btn
                            v-if="!user.model.hideAbout"
                            @click="hideAbout"
                            variant="light"
                            size="sm"
                        >
                            <font-awesome-icon icon="fa-light fa-eye-slash" />
                        </b-btn>
                        {{ user.story }}

                        <b-alert
                            v-if="user.model.hideAbout"
                            class="mt-2"
                            variant="danger"
                            show
                        >
                            About will not be shown to other users

                            <b-btn
                                @click="showAbout"
                                v-if="user.model.hideAbout"
                                variant="light"
                                class="text-danger ml-2"
                                size="sm"
                            >
                                Stop Hiding Profile
                            </b-btn>
                        </b-alert>
                    </b-td>
                </b-tr>
                <b-tr v-if="user.model.vettingRequired">
                    <b-th style="width: 20%" class="bg-light">
                        Vetting Required
                    </b-th>
                    <b-td class="py-0 align-middle">
                        <b-btn
                            @click="removeVettingRequired"
                            size="sm"
                            variant="light"
                        >
                            Remove Vetting Required
                        </b-btn>
                    </b-td>
                </b-tr>
                <b-tr v-if="user.model.email">
                    <b-th style="width: 20%" class="bg-light"> Email </b-th>
                    <b-td class="py-0 align-middle">
                        {{ user.model.email }}
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <user-photo-update />
        <user-photos />
        <user-message-photos />
        <user-related />
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserPhotoUpdate from "@/components/user/photo/UserPhotoUpdate";
import UserPhotos from "@/components/user/UserPhotos";
import UserMessagePhotos from "@/components/user/UserMessagePhotos";
import UserVideos from "@/components/user/UserVideos";
import UserHotspots from "@/components/user/UserHotspots";
import UserAlerts from "@/components/user/UserAlerts";
import UserRelated from "@/components/user/UserRelated";

export default {
    computed: {
        limitedPhotos() {
            return this.user.photos?.slice(0, 12);
        },
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        async wipeName() {
            await this.api.post(`/user/${this.user.model.id}/wipeName`);
            this.$store.dispatch("userEdit/refresh");
        },
        async hideAbout() {
            await this.api.post(`/user/${this.user.model.id}/hideAbout`);
            this.$store.dispatch("userEdit/refresh");
        },
        async showAbout() {
            await this.api.post(`/user/${this.user.model.id}/showAbout`);
            this.$store.dispatch("userEdit/refresh");
        },
        async setVetted() {
            await this.api.post(`/user/${this.user.model.id}/vetted`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeVetted() {
            await this.api.post(`/user/${this.user.model.id}/removeVetted`);
            this.$store.dispatch("userEdit/refresh");
        },
        async vettingRequired() {
            await this.api.post(`/user/${this.user.model.id}/vettingRequired`);
            this.$store.dispatch("userEdit/refresh");
        },
        async removeVettingRequired() {
            await this.api.post(
                `/user/${this.user.model.id}/removeVettingRequired`
            );
            this.$store.dispatch("userEdit/refresh");
        },
    },
    components: {
        UserPhotos,
        UserVideos,
        UserHotspots,
        UserAlerts,
        UserMessagePhotos,
        UserRelated,
        UserPhotoUpdate,
    },
};
</script>