<template>
    <b-alert
        variant="info"
        show
        class="mb-0 border-0 rounded-0 text-center py-5"
    >
        <b-container>
            <div>
                <h1>Authenticity</h1>

                <b-button
                    v-b-toggle.screeningHelp
                    variant="info"
                    size="sm"
                    class="ml-2"
                >
                    Training
                </b-button>
                <b-collapse id="screeningHelp">
                    <b-alert
                        v-if="trainingWheels"
                        variant="info"
                        show
                        class="small mt-3"
                    >
                        <font-awesome-icon
                            icon="fa-duotone fa-info-circle"
                            size="lg"
                        />
                        Authenticity checks are required from users who are
                        suspected of having managed accounts. Its a lighter
                        check than vetting, and does not block their access to
                        the app until any action is taken. This is a passive way
                        we can keep an eye on who is really using the account.
                        If the person in the video does not match the profile,
                        reject the authenticity check
                    </b-alert>
                </b-collapse>
            </div>

            <div class="d-flex align-items-center justify-content-around mt-3">
                <div>
                    <b-btn
                        :disabled="pauseScreening"
                        @click="approve"
                        variant="primary"
                        size="lg"
                        class="px-5"
                        block
                    >
                        Approve
                    </b-btn>
                </div>
                <div class="mt-3">
                    <b-btn
                        :disabled="pauseScreening"
                        @click="reject"
                        variant="danger"
                        size="lg"
                        class="px-5"
                        block
                    >
                        Reject
                    </b-btn>
                </div>
            </div>

            <h6>Videos</h6>
            <div v-if="userVideos" class="bg-light">
                <b-row no>
                    <b-col
                        md="4"
                        cols="12"
                        :key="userVideo.id"
                        v-for="{ userVideo, creatorAuthenticity } in userVideos"
                    >
                        <div class="rounded border mb-5">
                            <div
                                class="small p-2 bg-light d-flex justify-content-between"
                            >
                                <live-timestamp
                                    :timestamp="userVideo.created"
                                />

                                <div v-if="userVideo.seen">
                                    <font-awesome-icon
                                        icon="fa-duotone fa-eye"
                                        class="text-primary"
                                    />
                                </div>

                                {{ userVideo.status }}

                                <b-btn
                                    v-if="userVideo.stuck"
                                    variant="light"
                                    @click="deleteVideo(userVideo)"
                                    size="sm"
                                >
                                    <font-awesome-icon
                                        icon="fa-duotone fa-trash"
                                    />
                                    Stuck?
                                </b-btn>
                            </div>

                            <div>
                                <video
                                    controls
                                    class="img-fluid m-0 p-0"
                                    :src="userVideo.mp4Url"
                                ></video>
                            </div>

                            <b-table-simple borderless class="mb-0">
                                <b-thead>
                                    <b-tr>
                                        <b-th
                                            style="width: 1%"
                                            class="bg-light"
                                        >
                                            Code
                                        </b-th>
                                        <b-th>Video Transcript </b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <b-tr>
                                        <b-td class="bg-light">
                                            <code>
                                                {{ creatorAuthenticity.code }}
                                            </code>
                                        </b-td>
                                        <b-td>
                                            "{{ userVideo.transcript }}"
                                        </b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                        </div>
                    </b-col>
                </b-row>
            </div>
        </b-container>
    </b-alert>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            pauseScreening: true,
            userVideos: null,
        };
    },
    mounted() {
        setTimeout(() => {
            this.pauseScreening = false;
        }, 1000);

        this.refresh();
    },
    computed: {
        ...mapState("userEdit", ["user"]),
        ...mapState("user", ["trainingWheels"]),
    },
    methods: {
        pause() {
            this.pauseScreening = true;

            setTimeout(() => {
                this.pauseScreening = false;
            }, 3000);
        },
        refresh() {
            this.api
                .post(`/creatorAuthenticity/${this.user.model.id}/userVideos`)
                .then((response) => {
                    this.userVideos = response.data.userVideos;
                });
        },
        async approve() {
            this.pause();
            await this.api.post(
                `/creatorAuthenticity/${this.user.model.id}/approve`
            );
            this.$router.push("/creatorAuthenticity/index");
        },
        async reject() {
            this.pause();
            await this.api.post(
                `/creatorAuthenticity/${this.user.model.id}/reject`
            );
            this.$router.push("/creatorAuthenticity/index");
        },
    },
};
</script>