<template>
    <div>
        <b-row>
            <b-col>
                <h3>Current Meta</h3>
                <b-table-simple class="small" striped>
                    <b-thead>
                        <tr>
                            <th style="width: 1%"></th>
                            <th>Key</th>
                            <th>Created</th>
                        </tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr :key="meta.id" v-for="meta in metas">
                            <td>
                                <b-btn
                                    @click="removeMeta(meta)"
                                    size="sm"
                                    variant="light"
                                    class="text-danger text-truncate"
                                >
                                    <font-awesome-icon
                                        icon="fa-light fa-times"
                                    />
                                    Remove
                                </b-btn>
                            </td>
                            <td>{{ meta.key }}</td>
                            <td>{{ meta.createdRelative }}</td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
            <b-col>
                <h3>Available Meta</h3>
                <b-table-simple class="small" striped>
                    <b-thead>
                        <tr>
                            <th style="width: 1%"></th>
                            <th>Key</th>
                            <th>Created</th>
                        </tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr
                            :key="availableMeta.id"
                            v-for="availableMeta in availableMetas"
                        >
                            <td>
                                <b-btn
                                    @click="setMeta(availableMeta)"
                                    size="sm"
                                    variant="light"
                                    class="text-primary text-truncate"
                                >
                                    <font-awesome-icon
                                        icon="fa-light fa-arrow-left"
                                    />
                                    Apply
                                </b-btn>
                            </td>
                            <td>{{ availableMeta.key }}</td>
                            <td>{{ availableMeta.title }}</td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            availableMetas: null,
            metas: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        setMeta(meta) {
            this.api
                .post(`/userMeta/${this.user.model.id}/set`, {
                    key: meta.key,
                })
                .then(() => {
                    this.refresh();
                });
        },
        removeMeta(meta) {
            this.api
                .post(`/user/${this.user.model.id}/deleteMeta/${meta.id}`)
                .then(() => {
                    this.refresh();
                });
        },
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/metas`)
                .then((response) => {
                    this.metas = response.data.metas;
                });

            this.api
                .post(`/userMeta/${this.user.model.id}/available`)
                .then((response) => {
                    this.availableMetas = response.data.availableMetas;
                });
        },
    },
};
</script>