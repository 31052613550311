<template>
    <div>
        <b-badge
            v-if="kraken"
            variant="dark"
            :class="`text-${kraken.variant} mr-2`"
        >
            Kraken (Cash ${{ kraken.usdBalance }})
            <span> (BTC ~${{ kraken.btcBalance }}) </span>
        </b-badge>
        <b-badge
            v-if="wise"
            variant="dark"
            :class="`text-${wise.variant} mr-2`"
        >
            Wise ${{ wise.balance }}
        </b-badge>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("dashboard", ["wise", "kraken"]),
    },
};
</script>