<template>
    <div>
        <b-alert variant="warning" show v-if="photoUpdate?.isPending">
            <img
                class="img-fluid rounded float-right"
                :src="photoUpdate.userPhoto.small"
            />

            <h5>Pending Update</h5>

            <b-btn variant="primary" @click="approve(photoUpdate)">
                Approve
            </b-btn>
            <b-btn variant="danger" @click="reject(photoUpdate)" class="ml-3">
                Reject
            </b-btn>
        </b-alert>
    </div>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            photoUpdate: null,
        };
    },
    created() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/photo/${this.user.model.id}/photoUpdate`)
                .then((response) => {
                    this.photoUpdate = response.data.photoUpdate;
                });
        },
        async approve(userPhotoUpdate) {
            await this.api.post(
                `/userPhotoUpdate/${userPhotoUpdate.id}/approve`
            );

            this.refresh();

            this.$store.dispatch("userEdit/refresh");
        },
        async reject(userPhotoUpdate) {
            await this.api.post(
                `/userPhotoUpdate/${userPhotoUpdate.id}/reject`
            );

            this.refresh();
        },
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
};
</script>