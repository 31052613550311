<template>
    <div>
        <b-table-simple class="small" striped>
            <b-thead>
                <tr>
                    <th>From</th>
                    <th>To</th>
                    <th>Report</th>
                    <th>Status</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr :key="report.id" v-for="report in reports">
                    <td>
                        <user-avatar
                            v-if="report.fromUser"
                            :user="report.fromUser"
                        />
                    </td>
                    <td>
                        <user-avatar
                            v-if="report.toUser"
                            :user="report.toUser"
                        />
                    </td>

                    <td>
                        <div>{{ report.reason }}</div>
                        <div>{{ report.reportText }}</div>
                        <div>{{ report.createdRelative }}</div>
                    </td>
                    <td>
                        <b-badge
                            v-if="report.seen"
                            variant="light"
                            class="text-primary"
                        >
                            Seen
                        </b-badge>
                        <b-badge
                            v-if="report.deleted"
                            variant="light"
                            class="text-danger"
                        >
                            Deleted
                        </b-badge>
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            reports: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/reports`)
                .then((response) => {
                    this.reports = response.data.reports;
                });
        },
    },
};
</script>