<template>
    <div>
        <b-badge
            v-if="loadbalancer"
            variant="dark"
            :class="`text-${loadbalancer.variant} mr-2`"
        >
            Loadbalancer {{ loadbalancer.rps }}
        </b-badge>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("dashboard", ["loadbalancer"]),
    },
};
</script>